.benefits-section {
    background-color: #f0f8ff; /* Transparent blue background */
    text-align: center;
    padding: 50px;
    border-radius: 15px;
  }
  
  .benefits-section h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .benefits-section h2 span {
    color: #62a144; /* Green color for emphasis */
  }
  
  .benefits-section p {
    margin-bottom: 30px;
    font-size: 16px;
    color: #19358f;
  }
  
  .benefits-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns per row */
    gap: 20px;
  }
  
  .benefit-item {
    text-align: center;
  }
  
  .benefit-icon {
    width: 80px;
    height: 70px;
    margin-bottom: 10px;
  }
  
  .benefit-item p {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4;
    color: #162864;
  }
  