/* src/Components/FadeInSection/FadeInSection.css */

.fade-in-section {
    opacity: 0;
    transform: translateY(20px); /* Adjust as needed for slide effect */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .fade-in-section.is-visible {
    opacity: 1;
    transform: translateY(0); /* Reset transform to initial position */
  }
  