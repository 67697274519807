.express-pro-container {
  background-image: url("/src/Assets/backgroundimg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: space-around;
  background-color: #f0f0f0;
  color: #fff;
  padding: 40px;
  font-family: Arial, sans-serif;
}
span{
  color: #01273d;
  font-weight: 600;
  font-size: 30px;
}
.logo img {
  max-width: 80px;
  height: auto;
  margin-left: 27px;
  margin-top: -15px;
  transform: scale(1.5);
}
.promo-section {
  flex: 1;
  position: relative;
  color: #ffffff;
  margin-right: 0;
}

.promo-text {
  max-width: 400px;
  margin-bottom: 20px;
}
.lowest {
  margin-top: 100px;
}
.price {
  margin-top: -25px;
  margin-left: -27px;
}
.gurantee {
  color: #04f7ff;
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  margin-top: -40px;
}

.price-info {
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent dark overlay */
  padding: 15px;
  border-radius: 8px;
  display: inline-block;
  text-align: center;
  margin-top: 20px;
}

.price-info span {
  font-size: 1rem;
}

.price-info h2 {
  font-size: 2.5rem;
  color: #00eeff;
  margin: 0;
}

.price-info p {
  font-size: 1rem;
}

.contact-info {
  display: flex;
  gap: 20px;
  font-size: 0.9rem;
  align-items: center;
  margin-bottom: 20px;
  float: right;
  right: 0;
}

.contact-info a {
  color: #04f7ff;
  text-decoration: none;
  font-size: 18px;
}

.form-section {
  /* flex: 1; */
  background-color: #57b6c7;
  padding: 10px;
  border-radius: 8px;
  margin-top: 80px;
}

.form-section h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.form-section p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.form-section form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-section input,
.form-section textarea {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
}

.form-section form input,
.form-section form textarea {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 1rem;
  width: 95%;
  border-radius: 5px;
  border: none;
}

.form-section textarea {
  resize: none;
}

.form-section button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  width: 100%;
}

.form-section button:hover {
  background-color: #333;
}
