/* BusinessExpress.css */

.business-support-container {
    background-image: url(/src/Assets/expresslogo.png);
    background-size: cover;
    background-position: center;
    background-clip: content-box;
    background-attachment: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
    min-height: 300px; /* Set a minimum height to prevent layout shifts */
    position: relative;
    overflow: hidden;
  }
  
  .text-content,
  .support-card {
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .text-content {
    margin-left: 100px;
    max-width: 50%;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
  }
  
  .text-content h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .text-content .highlight {
    color: #021847;
    font-weight: 600;
  }
  
  .text-content p {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }
  
  .support-card {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 30px;
    padding: 20px;
    max-width: 400px;
    height: 200px;
    text-align: left;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    color: rgb(23, 75, 121);
  }
  
  .card-icon {
    text-align: center;
    margin-bottom: 15px;
  }
  
  .card-icon img {
    width: 50px;
    height: 50px;
  }
  
  .support-card h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .support-card p {
    font-size: 14px;
    color: #02111f;
    font-weight: 600;
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  