.consultation-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #0a1f44; /* Dark blue shade */
    padding: 20px;
    color: #fff;
  }
  
  .consultation-expert {
    text-align: center;
    width: 20%;
  }
  
  .expert-info {
    margin-bottom: 10px;
  }
  
  .expert-info h3 {
    font-size: 16px;
    color: #c4abff; /* Light green for names */
    border-bottom: 2px solid #b3d1ff;
  }
  
  .expert-info p {
    font-size: 14px;
  }
  
  .expert-image {
    width: 100%;
    border-radius: 50%; /* Circular image */
  }
  
  .consultation-message {
    background-color: #1a3f68; /* Lighter blue shade for contrast */
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    flex: 1;
    margin: 0 20px;
  }
  
  .consultation-message h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .consultation-message p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .callback-button {
    background-color: #0e555a; /* Green button */
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .callback-button:hover {
    background-color: #040925;
  }
  