/* ContactForm.css */

.form-container {
    background-color: #111;
    color: #fff;
    padding: 30px;
    border-radius: 10px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .input-group {
    display: flex;
    gap: 15px;
  }
  
  input,
  textarea {
    flex: 1;
    padding: 10px;
    border-radius: 100px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    color: #0e0b0b;
  }
  
  .textarea1 {
    margin-left: 350px;
    resize: none;
    height: 100px;
    width: 50%;
  }
  
  .button1 {
    padding: 10px 20px;
    background-color: #083357;
    color: #fff;
    margin-left: 520px;
    border: none;
    border-radius: 50px;
    width: 400px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .button1:hover {
    background-color: #067483;
  }
  