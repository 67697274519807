.footer {
    background-color: #202020;
    color: #ffffff;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-left,
  .footer-right {
    width: 50%;
    padding: 20px;
  }
  
  .footer-left {
    background-color: #202020;
    color: #ffffff;
    text-align: left;
  }
  
  .footer-right {
    background-color: #ffffff;
    color: #202020;
    text-align: left;
  }
  
  .footer-left h3 {
    font-size: 20px;
    font-weight: bold;
  }
  
  .footer-left p {
    margin: 5px 0;
  }
  
  .footer-left .guaranteed {
    color: #52b8bf;
    font-weight: bold;
    margin: 10px 0 5px;
  }
  
  .footer-left .amount {
    font-size: 24px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    color: #61bdb8;
  }
  
  .footer-right a {
    color: #52bbbf;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-decoration: none;
  }
  
  .footer-right a:hover {
    text-decoration: underline;
  }
  
  .footer-contact {
    text-align: center;
    margin-top: 20px;
  }
  
  .footer-contact p {
    margin: 5px 0;
  }
  
  .footer-contact a {
    color: #52bbbf;
    text-decoration: none;
  }
  
  .footer-contact a:hover {
    text-decoration: underline;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
  }
  
  .social-icons a {
    color: #52bbbf;
    font-size: 18px;
  }
  
  .footer-bottom {
    border-top: 1px solid #555;
    padding-top: 10px;
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
  }
  