.testimonials-container {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
  }
  
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .header {
    flex: 1;
    padding-right: 20px;
  }
  
  .header h2 {
    color: #333;
    font-size: 24px;
  }
  
  .header h2 span {
    color: #0099ff;
  }
  
  .header p {
    color: #666;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .header ul {
    list-style: none; /* Remove default bullets */
    padding-left: 0; /* Remove default list padding */
    margin: 0; /* Reset any margin on the list */
  }
  
  .header ul li {
    position: relative; /* Allows precise positioning of bullets */
    padding-left: 1px; /* Create space for the bullet */
    margin-bottom: 8px; /* Spacing between list items */
    font-size: 25px; /* Ensure uniform text size */
    color: #053b8d; /* Text color */
  }
  
  .header ul li::before {
    content: "•"; /* Custom bullet */
    position: absolute; /* Align bullet precisely */
    left: 0; /* No gap between bullet and list item */
    top: 0.25em; /* Vertical alignment adjustment */
    font-size: 16px; /* Same size as the text */
    color: #003699; /* Green bullet color */
  }
  
  /* Right section that contains Google rating and testimonial box */
  .right-section {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  /* Google Rating Styling */
  .rating {
    width: 100%;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    text-align: left;
  }
  
  .google-rating-text {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  /* Google colors */
  .google-color { color: #4285F4; }
  .red-color { color: #EA4335; }
  .yellow-color { color: #FBBC05; }
  .green-color { color: #34A853; }
  
  .stars {
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  
  .rating-value {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .stars-icons {
    color: #ffb400;
  }
  
  .reviews {
    color: #333;
    margin-left: 10px;
  }
  
  /* Testimonial Box */
  .testimonial-box {
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
  }
  
  .testimonial-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .google-logo {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  .testimonial-box h4 {
    font-size: 18px;
    margin: 0;
  }
  
  .testimonial-box p {
    font-size: 16px;
    line-height: 1.4;
  }
  
  .next-arrow {
    background: none;
    border: none;
    color: #ffb400;
    font-size: 24px;
    cursor: pointer;
    margin-top: 10px;
  }
  