/* GovernmentAgencies.css */

.agencies-container {
  background-color: #f0f8ff;
  text-align: center;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
}

.agencies-container h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.highlight {
  font-size: 30px;
  font-weight: 600;
  color: #0077ff;
}

.agencies-container p {
  font-size: 16px;
  font-weight: 500;
  color: #021258;
  margin-bottom: 20px;
}

.agencies-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}

.logo-item {
  flex: 0 1 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-item img {
  width: 250px;
  height: auto;
  object-fit: contain;
}
