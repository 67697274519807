.license-container {
  text-align: center;
  padding: 50px;
  background-color: #0a1f44;
  color: #fff;
}

.intro-section {
  margin-bottom: 40px;
}

.intro-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.intro-section p {
  font-size: 16px;
  line-height: 1.5;
}

.info-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 20px;
}

.info-box {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  flex: 1;
}

.info-box h3 {
  color: #00eeff;
  margin-bottom: 10px;
}

.info-box p {
  font-size: 14px;
  line-height: 1.5;
}

.license-heading {
  margin-bottom: 40px;
  font-size: 32px;
}

.license-card-main {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.license-card-main .license-sub {
  height: 550px;
}

.license-card-main .card-features {
  height: 430px;
}

.license-card .card-subtitle {
  height: 50px;
}

.card {
  background-color: #1a1a1a;
  border: 1px solid #2b2b2b;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.card-title {
  color: #00eeff;
  font-size: 24px;
  margin-bottom: 10px;
}

.card-subtitle {
  font-size: 18px;
  margin-bottom: 15px;
}

.card-price {
  font-size: 36px;
  color: #8eeef5;
  margin-bottom: 20px;
}

.card-features {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  text-align: left;
}

.card-features li {
  margin-bottom: 10px;
}

.enquire-button {
  background-color: #0e555a;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.enquire-button:hover {
  background-color: #45a049;
}
